import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/layout';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: '/home',
        component: () => import('@/views/Home/index'),
        meta: { title: 'Home' },
      },
      {
        path: 'parent',
        name: '/parent',
        component: () => import('@/views/Product/parent/index'),
        meta: { title: 'parent' },
      },
      {
        path: 'teacher',
        name: '/teacher',
        component: () => import('@/views/Product/teacher/index'),
        meta: { title: 'teacher' },
      },
      {
        path: 'restaurant',
        name: '/restaurant',
        component: () => import('@/views/Product/restaurant/index'),
        meta: { title: 'restaurant' },
      },
      {
        path: 'pickup',
        name: '/pickup',
        component: () => import('@/views/Product/pickup/index'),
        meta: { title: 'pickup' },
      },
      {
        path: 'stay',
        name: '/stay',
        component: () => import('@/views/Product/stay/index'),
        meta: { title: 'stay' },
      },
      {
        path: 'restaurants',
        name: '/restaurants',
        component: () => import('@/views/solution/restaurants/index'),
        meta: { title: 'restaurants' },
      },
      {
        path: 'student',
        name: '/student',
        component: () => import('@/views/solution/student/index'),
        meta: { title: 'student' },
      },
      {
        path: 'pickups',
        name: '/pickups',
        component: () => import('@/views/solution/pickups/index'),
        meta: { title: 'pickups' },
      },
      {
        path: 'stays',
        name: '/stays',
        component: () => import('@/views/solution/stays/index'),
        meta: { title: 'stays' },
      },
      {
        path: 'information',
        name: '/information',
        component: () => import('@/views/information/index'),
        meta: { title: 'information' },
      },
      {
        path: 'informationDetails',
        name: '/informationDetails',
        component: () => import('@/views/informationDetails/index'),
        meta: { title: 'informationDetails' },
      },
      {
        path: 'intelligence',
        name: '/intelligence',
        component: () => import('@/views/intelligence/index'),
        meta: { title: 'intelligence' },
      },
      {
        path: 'about',
        name: '/about',
        component: () => import('@/views/about/index'),
        meta: { title: 'about' },
      },
      {
        path: 'join',
        name: '/join',
        component: () => import('@/views/join/index'),
        meta: { title: 'join' },
      },
    ],
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  // },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
