<template>
  <div class="footer">
    <!-- <Customer></Customer> -->
    <div class="second">
      <div class="top">
        <div class="one">
          <img src="@/assets/logo-white.png" />
          <p>服务热线：0310-12345678</p>
          <p>商务合作：0310-12345678</p>
        </div>
        <div class="common two">
          <p
            v-for="(item, index) in center"
            :key="index"
            @click="centers(index)"
          >
            {{ item }}
          </p>
        </div>
        <div class="common three">
          <p
            v-for="(item, index) in solution"
            :key="index"
            @click="solutions(index)"
          >
            {{ item }}
          </p>
        </div>
        <div class="common four">
          <p
            v-for="(item, index) in information"
            :key="index"
            @click="informations(index)"
          >
            {{ item }}
          </p>
        </div>
        <div class="common five">
          <p @click="join">我要加盟</p>
          <p @click="join">加盟流程</p>
        </div>
        <div class="common six">
          <p @click="about()">关于我们</p>
          <p>
            <img src="@/assets/weixin.png" />
            <img src="@/assets/weibo.png" />
          </p>
        </div>
        <div class="common seven">
          <p @click="about()">关于我们</p>
          <p><img src="@/assets/qrcode.png" /></p>
        </div>
      </div>
      <div class="bottom">
        <p></p>
        <div class="box">
          <span
            >Cpyright © 2023xingmany.com版权所有 邯郸星满院科技有限公司 -</span
          >
          <div class="right">
            <span class="span" @click.stop="filings">冀ICP备2023041204号</span>
            <div class="div" @click.stop="filing">
              <img src="../../assets/national.png" alt="" />
              <span>冀公网安备13040302001613号</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Customer from '@/components/customer.vue';
export default {
  data() {
    return {
      center: [
        '产品中心',
        '家长端',
        '学生端',
        '教师端',
        '智能接送系统',
        '智能餐饮系统',
        '智能住宿系统',
      ],
      solution: ['解决方案', '接送服务', '住宿服务', '餐饮服务'],
      information: ['资讯中心', '行业新闻', '中心动态', '育儿知识库'],
    };
  },
  components: {
    Customer,
  },
  methods: {
    // 跳转产品中心
    centers(index) {
      if (index == 0) {
        this.$router.push('/parent').catch((err) => {}); //产品中心 家长端
      } else if (index == 1) {
        //家长端
        this.$router.push('/parent').catch((err) => {});
      } else if (index == 2) {
        //学生端
        this.$router.push('/student');
      } else if (index == 3) {
        //教师端
        this.$router.push('/teacher').catch((err) => {});
      } else if (index == 4) {
        //智能接送系统
        this.$router.push('/pickup').catch((err) => {});
      } else if (index == 5) {
        //智能餐饮系统
        this.$router.push('/restaurant').catch((err) => {});
      } else if (index == 6) {
        //智能宿系统
        this.$router.push('/stay').catch((err) => {});
      }
    },
    //解决方案
    solutions(index) {
      if (index == 0) {
        //解决方案餐饮服务
        this.$router.push('/restaurants').catch((err) => {});
      } else if (index == 1) {
        //接送服务
        this.$router.push('/pickups').catch((err) => {});
      } else if (index == 2) {
        //住宿服务
        this.$router.push('/restaurants').catch((err) => {});
      } else if (index == 3) {
        //餐饮服务·
        this.$router.push('/stays').catch((err) => {});
      }
    },
    //资讯中心
    informations(index) {
      this.$router.push('/information').catch((err) => {});
    },
    //关于我们
    about() {
      this.$router.push('/about').catch((err) => {});
    },
    //我要加盟
    join() {
      this.$router.push('/join').catch((err) => {});
    },
    // 新窗口打开备案号
    filings() {
      console.log('111111');
      window.open('https://beian.miit.gov.cn/', '_blank');
    },
    //新窗口打开公安号
    filing() {
      window.open(
        'https://beian.mps.gov.cn/#/query/webSearch?code=13040302001613',
        '_blank'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 100%;
}

.second {
  // margin-top: 1.25rem;
  background: #000;
  height: 5rem;
  color: #fff;
  .top {
    height: 4.25rem;
    display: flex;
    padding: 0.5625rem 3.25rem;
    box-sizing: border-box;
  }
  .one {
    text-align: right;
    img {
      width: 3.45rem;
      height: 1rem;
    }
    p {
      font-size: 0.175rem;
      line-height: 0.175rem;
      color: #a5a4aa;
    }
    p:first-of-type {
      margin-top: 0.75rem;
    }
    p:last-of-type {
      margin-top: 0.25rem;
    }
  }
  .common {
    text-align: left;
    font-size: 0.175rem;
    color: #a5a4aa;
    p {
      min-width: 1.05rem;
    }
    p:first-of-type {
      color: #fff;
      font-size: 0.2rem;
      margin-bottom: 0.275rem;
    }
    p:not(:first-child) {
      font-size: 0.175rem;
      margin-top: 0.25rem;
    }
  }
  .two {
    margin-left: 2.775rem;
    p {
      cursor: pointer;
    }
    p:hover {
      color: #fe6869;
    }
  }
  .three,
  .four,
  .five,
  .six,
  .seven {
    margin-left: 1rem;
    p {
      cursor: pointer;
    }
    p:hover {
      color: #fe6869;
    }
  }
  .six {
    img {
      width: 0.375rem;
      height: 0.375rem;
      text-align: left;
    }
    img:first-of-type {
      margin-right: 0.25rem;
    }
  }
  .seven {
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .bottom {
    height: 0.7375rem;
    line-height: 0.7375rem;
    font-size: 0.15rem;
    color: #a5a4aa;
    cursor: pointer;
    p {
      border-top: 1px solid #dfe3ea;
      opacity: 0.2;
      background: #dfe3ea;
    }
    .right {
      display: flex;
    }
    .span {
      color: #3760a9;
      font-size: 0.15rem;
    }
    .box {
      display: flex;
      align-items: center;
      justify-content: center;
      .right {
        img {
          width: 0.25rem;
          height: 0.25rem;
          margin-top: 0.22rem;
          margin-right: 0.05rem;
        }
        .div {
          display: flex;
          margin-left: 0.1rem;
        }
      }
    }
  }
}
</style>
