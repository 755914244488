/**
 * 千分位逗号分割
 * @param {*} num
 * @returns
 */
function addDou(num) {
  const numArr = num.split('.');
  num = numArr[0];
  let result = '';
  while (num.length > 3) {
    result = ',' + num.slice(-3) + result;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  if (numArr[1]) {
    result += '.' + numArr[1];
  }

  return result;
}

// js实现动态变化的数字
const numberChange = {
  inserted(el, binding) {
    // 动画间隔
    const rate = 30;
    // 总时长let
    const time = 2000;
    const finalNum = Number(el.innerText);
    // 步长,数据增加间隔
    const step = Number(finalNum / (time / rate));
    // 初始值
    let count = 0;
    let timer = setInterval(() => {
      count = Number(count + step);
      el.innerText = addDou(count.toFixed(0));
      if (count > finalNum) {
        // 避免count大于finalNum最终数字显示不对
        count = finalNum;
        el.innerText = addDou(count.toFixed(0));
        // 清空定时器
        clearInterval(timer);
        timer = null;
      }
    }, rate);
  },
};
export default numberChange;
