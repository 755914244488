<template>
  <div class="mark">
    <div class="content">
      <div class="left">
        <img src="../assets/enterprise-left.png" alt="" />
      </div>
      <div class="right">
        <div class="cha" @click="cancel">x</div>
        <div class="title">输入您的信息，开启试用之旅</div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="怎么称呼您" prop="name">
            <el-input v-model="ruleForm.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="您的手机号" prop="name">
            <el-input v-model="ruleForm.phone" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
        <div class="div">
          <img class="imgs" src="../../src/assets/five-star.png" alt="" />
          <div class="span">您的角色是</div>
          <input
            class="fruits"
            v-model="ruleForm.role"
            type="text"
            placeholder="请输入您的角色"
            readonly
            @click="stateClick"
          />
          <div class="Required" v-if="requiredFlag">请输入您的角色</div>
          <div class="img">
            <img
              v-if="imgFlag"
              src="../assets/down-arrowShang.png"
              alt=""
              @click="clickImg(1)"
            />
            <img
              v-else
              src="../assets/down-arrowXia.png"
              alt=""
              @click="clickImg(2)"
            />
          </div>
          <div class="juese" v-if="jueseFlag">
            <div @click="jueseInput('家长')"><span>家长</span></div>
            <div @click="jueseInput('教师')"><span>教师</span></div>
            <div @click="jueseInput('加盟商')"><span>加盟商</span></div>
          </div>
        </div>
        <div class="apply" @click="submitForm('ruleForm')">申请试用</div>
      </div>
    </div>
  </div>
</template>

<script>
import user from '@/api/user';
export default {
  name: '',
  mixins: [],
  components: {},
  props: ['cancelMark', 'aa'],
  data() {
    return {
      jueseFlag: false,
      imgFlag: false,
      requiredFlag: false,
      ruleForm: {
        name: '',
        phone: '',
        role: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' },
        ],
        role: [
          { required: true, message: '请选择活动区域', trigger: 'change' },
        ],
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    stateClick() {
      this.jueseFlag = !this.jueseFlag;
    },
    //关闭弹框
    cancel() {
      this.requiredFlag = false;
      this.$emit('cancelMark');
      this.ruleForm.phone = '';
      this.ruleForm.name = '';
      this.ruleForm.role = '';
    },
    // 下拉框
    jueseInput(name) {
      this.ruleForm.role = name;
      this.jueseFlag = false;
      this.imgFlag = false;
      this.requiredFlag = false;
    },
    // 下拉菜单
    clickImg(index) {
      if (index == 1) {
        this.jueseFlag = false;
        this.imgFlag = false;
      } else if (index == 2) {
        this.jueseFlag = true;
        this.imgFlag = true;
      }
    },
    validatePhoneNumber(phoneNumber) {
      // 使用正则表达式进行手机号验证
      var pattern = /^1[0-9]{10}$/;
      return !pattern.test(phoneNumber);
    },
    // 提交
    submitForm(formName) {
      this.types = 1;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.validatePhoneNumber(this.ruleForm.phone)) {
            alert('请输入正确的手机号');
          } else if (this.ruleForm.role == '') {
            this.requiredFlag = true;
          } else {
            user
              .applyTrail(this.ruleForm)
              .then((res) => {
                alert(res.msg);
                this.mark = false;
                this.ruleForm.phone = '';
                this.ruleForm.name = '';
                this.ruleForm.role = '';
              })
              .catch((err) => {});
          }
        } else {
          console.log('error submit!!');
          if (this.ruleForm.role == '') {
            this.requiredFlag = true;
          } else {
            this.requiredFlag = false;
          }
          return false;
        }
      });
    },
    // 触发弹框 input焦点
    handleFocus() {
      console.log('input触发');
      if (this.ruleForm.role == '') {
        this.requiredFlag = true;
      } else {
        this.requiredFlag = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.mark {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 1;
  z-index: 99999999999999 !important;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999999;
  // position: relative;
  .content {
    width: 10rem;
    height: 6.375rem;
    background: #ffffff;
    border-radius: 0.2rem;
    display: flex;
    position: absolute;
    left: calc(50% - 5rem);
    top: calc(50% - 3.1875rem);
    opacity: 1;
    .left {
      width: 3.0625rem;
      height: 6.375rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      width: 6.9375rem;
      position: relative;

      .cha {
        font-size: 0.375rem;
        // display: block;
        color: #000000;
        position: absolute;
        top: 0.1rem;
        right: 0.375rem;
        cursor: pointer;
      }
      .title {
        width: 4.875rem;
        height: 0.375rem;
        font-size: 0.375rem;
        font-weight: bold;
        color: #000000;
        line-height: 0.375rem;
        margin: 0.75rem 0rem 0rem 1.025rem;
      }
      el-form {
        z-index: 99999999999;
      }
      ::v-deep {
        .el-form {
          margin-top: 0.6875rem;
          margin-left: 0.75rem;
        }
        .el-form-item {
          width: 5.4375rem;
          margin-bottom: 0.4375rem;
        }
        .el-form-item__label {
          margin-top: 0.1rem;
        }
        .el-form-item__content ::after {
          background-color: red;
        }
        .el-input__inner {
          width: 4.1875rem;
          height: 0.625rem;
          background: #ffffff;
          border-radius: 0.3125rem;
          border: 0.0125rem solid #e2e2e1;
        }
      }
      .div {
        width: 5.4375rem;
        margin-left: 0.75rem;
        display: flex;
        position: relative;
        .imgs {
          width: 0.075rem;
          height: 0.075rem;
          position: absolute;
          top: 0.28rem;
          left: 0.1rem;
        }
        .span {
          width: 1.2rem;
          // margin-right: 0.3rem;
          display: block;
          margin-top: 0.25rem;
        }
        .fruits {
          width: 4.1875rem;
          height: 0.625rem;
          background: #ffffff;
          border-radius: 0.3125rem;
          border: 0.0125rem solid #e2e2e1;
          margin-left: 0.05rem;
          outline: none;
          text-indent: 1em;
          position: relative;

          ::placeholder {
            color: #c6cad1;
          }
        }
        .img {
          width: 0.2rem;
          height: 0.2rem;
          position: absolute;
          top: 0.25rem;
          right: 0.375rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .Required {
          color: #f56c6c;
          font-size: 0.15rem;
          position: absolute;
          left: 1.25rem;
          top: 0.65rem;
        }
        .juese {
          width: 4.1875rem;
          height: 1.5rem;
          background: #ffffff;
          box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.1);
          border-radius: 0.2rem;
          position: absolute;
          right: 0rem;
          top: 0.725rem;
          div {
            width: 4.1875rem;
            height: 0.5rem;
            background: #ffffff;
            border-radius: 0.1rem;
            height: 0.375rem;
            display: flex;
            align-items: center;
            text-align: left;
            line-height: 0.5rem;
            // padding-left: 0.5rem;
            margin-top: 0.1rem;
            span {
              margin-left: 0.5rem;
            }
          }
          div:hover {
            width: 4.1875rem;
            height: 0.5rem;
            background: #f5f5f5;
            border-radius: 0.1rem;
            display: flex;
            align-items: center;
            text-align: left;
            line-height: 0.5rem;
            // padding-left: 0.5rem;
            margin-top: 0.1rem;
            span {
              margin-left: 0.5rem;
            }
          }
        }
      }
      .apply {
        width: 4.1875rem;
        height: 0.625rem;
        background: #000000;
        border-radius: 0.3125rem;
        color: #ffffff;
        font-size: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.6875rem 0rem 0rem 2rem;
        z-index: -99999;
      }
    }
  }
  .contents {
    width: 10rem;
    height: 6.375rem;
    background: #ffffff;
    border-radius: 0.2rem;
    display: flex;
    position: absolute;
    left: calc(50% - 5rem);
    top: calc(50% - 4.1875rem);
    opacity: 1;
    .left {
      width: 3.0625rem;
      height: 6.375rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      width: 6.9375rem;
      position: relative;

      .cha {
        font-size: 0.375rem;
        // display: block;
        color: #000000;
        position: absolute;
        top: 0.1rem;
        right: 0.375rem;
      }
      .title {
        width: 4.875rem;
        height: 0.375rem;
        font-size: 0.375rem;
        font-weight: bold;
        color: #000000;
        line-height: 0.375rem;
        margin: 0.75rem 0rem 0rem 1.025rem;
      }
      el-form {
        z-index: 99999999999;
      }
      ::v-deep {
        .el-form {
          margin-top: 0.6875rem;
          margin-left: 0.75rem;
        }
        .el-form-item {
          width: 5.4375rem;
          margin-bottom: 0.4375rem;
        }
        .el-form-item__label {
          margin-top: 0.1rem;
        }
        .el-form-item__content ::after {
          background-color: red;
        }
        .el-input__inner {
          width: 4.1875rem;
          height: 0.625rem;
          background: #ffffff;
          border-radius: 0.3125rem;
          border: 0.0125rem solid #e2e2e1;
        }
      }
      .div {
        width: 5.4375rem;
        margin-left: 0.75rem;
        display: flex;
        position: relative;
        .imgs {
          width: 0.075rem;
          height: 0.075rem;
          position: absolute;
          top: 0.28rem;
          left: 0.1rem;
        }
        .span {
          width: 1.2rem;
          // margin-right: 0.3rem;
          display: block;
          margin-top: 0.25rem;
        }
        .fruits {
          width: 4.1875rem;
          height: 0.625rem;
          background: #ffffff;
          border-radius: 0.3125rem;
          border: 0.0125rem solid #e2e2e1;
          margin-left: 0.05rem;
          outline: none;
          text-indent: 1em;
          position: relative;

          ::placeholder {
            color: #c6cad1;
          }
        }
        .img {
          width: 0.2rem;
          height: 0.2rem;
          position: absolute;
          top: 0.25rem;
          right: 0.375rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .Required {
          color: #f56c6c;
          font-size: 0.15rem;
          position: absolute;
          left: 1.25rem;
          top: 0.65rem;
        }
        .juese {
          width: 4.1875rem;
          height: 1.5rem;
          background: #ffffff;
          box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.1);
          border-radius: 0.2rem;
          position: absolute;
          right: 0rem;
          top: 0.725rem;
          div {
            width: 4.1875rem;
            height: 0.5rem;
            background: #ffffff;
            border-radius: 0.1rem;
            height: 0.375rem;
            display: flex;
            align-items: center;
            text-align: left;
            line-height: 0.5rem;
            // padding-left: 0.5rem;
            margin-top: 0.1rem;
            span {
              margin-left: 0.5rem;
            }
          }
          div:hover {
            width: 4.1875rem;
            height: 0.5rem;
            background: #f5f5f5;
            border-radius: 0.1rem;
            display: flex;
            align-items: center;
            text-align: left;
            line-height: 0.5rem;
            // padding-left: 0.5rem;
            margin-top: 0.1rem;
            span {
              margin-left: 0.5rem;
            }
          }
        }
      }
      .apply {
        width: 4.1875rem;
        height: 0.625rem;
        background: #000000;
        border-radius: 0.3125rem;
        color: #ffffff;
        font-size: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.6875rem 0rem 0rem 2rem;
        z-index: -99999;
      }
    }
  }
}
</style>
