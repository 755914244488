<template>
  <div class="header-box">
    <div :class="this.$route.path == '/home' ? 'header' : 'headers'">
      <div class="header_logo">
        <img src="@/assets/logo.png" />
      </div>
      <div class="header_nav">
        <ul>
          <li
            v-for="(item, index) in nav"
            :key="index"
            @mouseenter="showDropdown(index)"
            @mouseleave="leave"
          >
            <span
              :class="index == indexs ? 'active' : ''"
              @click="tab(index, item)"
              >{{ item.name }}</span
            >
            <span class="bold" v-if="index == indexs"></span>
            <div v-if="item.children">
              <ul v-if="activeIndex === index" class="li-item">
                <!-- <ul class="li-item"> -->
                <li
                  v-for="(dropdownItem, dropdownIndex) in item.children"
                  :key="dropdownIndex"
                  @click="tab(index, dropdownItem)"
                >
                  <a>{{ dropdownItem.title }}</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="right">
        <span>
          <img src="@/assets/phone.png" />
          0310-7058999
        </span>
        <span class="btn" @click="markAlert">免费试用</span>
        <span
          class="btn btn_app"
          @mouseenter="caseentry"
          @mouseleave="caseLeave"
          >下载APP</span
        >
        <div class="app" v-if="flag">
          <div class="divs">
            <img src="../../assets/home-code.png" alt="" />
            <div>安卓下载</div>
          </div>
          <div class="divs">
            <img src="../../assets/home-code.png" alt="" />
            <div>ios下载</div>
          </div>
        </div>
      </div>
    </div>
    <div
      :class="this.$route.path == '/home' ? 'mark-boxs' : 'mark'"
      v-if="mark"
    >
      <marks @cancelMark="cancel"></marks>
      <!-- <div class="content">
        <div class="left">
          <img src="../../assets/enterprise-left.png" alt="" />
        </div>
        <div class="right">
          <div class="cha" @click="cancel">x</div>
          <div class="title">输入您的信息，开启试用之旅</div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="怎么称呼您" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="您的手机号" prop="name">
              <el-input
                v-model="ruleForm.phone"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="div">
            <img class="imgs" src="../../../src/assets/five-star.png" alt="" />
            <div class="span">您的角色是</div>
            <input
              class="fruits"
              v-model="ruleForm.role"
              type="text"
              placeholder="请输入您的角色"
              readonly
              @click="stateClick"
              @focus="handleFocus"
            />
            <div class="Required" v-if="requiredFlag">请输入您的角色</div>
            <div class="img">
              <img
                v-if="imgFlag"
                src="../../assets/down-arrowShang.png"
                alt=""
                @click="clickImg(1)"
              />
              <img
                v-else
                src="../../assets/down-arrowXia.png"
                alt=""
                @click="clickImg(2)"
              />
            </div>
            <div class="juese" v-if="jueseFlag">
              <div @click="jueseInput('家长')"><span>家长</span></div>
              <div @click="jueseInput('教师')"><span>教师</span></div>
              <div @click="jueseInput('加盟商')"><span>加盟商</span></div>
            </div>
          </div>
          <div class="apply" @click="submitForm('ruleForm')">申请试用</div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import user from "@/api/user";
import marks from "@/components/mark.vue";
export default {
  components: { marks },
  data() {
    return {
      flag: false,
      mark: false,
      indexs: 0,
      idx: 0,
      activeIndex: -1,
      jueseFlag: false,
      imgFlag: false,
      requiredFlag: false,
      ruleForm: {
        name: "",
        phone: "",
        role: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' },
        ],
        role: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
      },
      nav: [
        {
          name: "首页",
          path: "/home",
        },
        {
          name: "产品中心",
          path: "/parent",
          children: [
            {
              title: "家长端",
              path: "/parent",
            },
            {
              title: "学生端",
              path: "/student",
            },
            {
              title: "老师端",
              path: "/teacher",
            },
            {
              title: "智能接送系统",
              path: "/pickup",
            },
            {
              title: "智能餐饮系统",
              path: "/restaurant",
            },
            {
              title: "智能住宿系统",
              path: "/stay",
            },
          ],
        },
        {
          name: "解决方案",
          path: "/restaurants",
          children: [
            {
              title: "餐饮服务",
              path: "/restaurants",
            },
            {
              title: "接送服务",
              path: "/pickups",
            },
            {
              title: "住宿服务",
              path: "/stays",
            },
          ],
        },
        {
          name: "资讯中心",
          path: "/information",
        },
        {
          name: "关于我们",
          path: "/about",
        },
        {
          name: "我要加盟",
          path: "/join",
        },
        {
          name: "智能AI",
          path: "/intelligence",
        },
      ],
    };
  },
  mounted() {
    let idx = localStorage.getItem("key");
    this.indexs = idx;
    console.log(idx, "225225");
  },
  methods: {
    stateClick() {
      this.jueseFlag = !this.jueseFlag;
    },
    // 下拉菜单
    clickImg(index) {
      if (index == 1) {
        this.jueseFlag = false;
        this.imgFlag = false;
      } else if (index == 2) {
        this.jueseFlag = true;
        this.imgFlag = true;
      }
    },
    // 高亮 并且切换页面
    tab(index, item) {
      this.indexs = index;
      localStorage.setItem("key", this.indexs);
      this.$router.push(item.path).catch((err) => {});
    },
    // 下拉框
    jueseInput(name) {
      this.ruleForm.role = name;
      this.jueseFlag = false;
      this.imgFlag = false;
      this.requiredFlag = false;
    },
    // 下拉菜单
    showDropdown(index) {
      this.activeIndex = index;
    },
    // 鼠标离开
    leave() {
      this.activeIndex = -1;
    },
    // 鼠标指向 下载app
    caseentry() {
      this.flag = true;
    },
    // 鼠标离开 下载app
    caseLeave() {
      this.flag = false;
    },
    // 打开免费使用弹框
    markAlert() {
      this.mark = true;
    },
    // 关闭免费使用弹框
    cancel() {
      this.mark = false;
      this.requiredFlag = false;
      this.ruleForm.phone = "";
      this.ruleForm.name = "";
      this.ruleForm.role = "";
    },
    validatePhoneNumber(phoneNumber) {
      // 使用正则表达式进行手机号验证
      var pattern = /^1[0-9]{10}$/;
      return !pattern.test(phoneNumber);
    },
    // 提交
    submitForm(formName) {
      this.types = 1;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.validatePhoneNumber(this.ruleForm.phone)) {
            alert("请输入正确的手机号");
          } else if (this.ruleForm.role == "") {
            this.requiredFlag = true;
          } else {
            user
              .applyTrail(this.ruleForm)
              .then((res) => {
                alert(res.msg);
                this.mark = false;
                this.ruleForm.phone = "";
                this.ruleForm.name = "";
                this.ruleForm.role = "";
              })
              .catch((err) => {});
          }
        } else {
          console.log("error submit!!");
          if (this.ruleForm.role == "") {
            this.requiredFlag = true;
          } else {
            this.requiredFlag = false;
          }
          return false;
        }
      });
    },
    // 触发弹框 input焦点
    handleFocus() {
      console.log("input触发");
      if (this.ruleForm.role == "") {
        this.requiredFlag = true;
      } else {
        this.requiredFlag = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header-box{
  position: fixed;
  top: 0rem;
  left: 0rem;
  width: 24rem;
  height: 0.9rem;
  z-index: 9999;
}
.header {
  width: 100%;
  height: 100%;
  line-height: 0.9rem;
  display: flex;
  justify-content: center;
  border-bottom: 0.0125rem solid rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(.125rem);
  .header_logo {
    img {
      width: 2.15rem;
      height: 0.625rem;
      vertical-align: middle;
    }
  }
  .header_nav {
    height: 0.9rem;
    line-height: 0。9rem;
    cursor: pointer;
    margin-left: 1.8625rem;
    box-sizing: border-box;
    .tab {
      //   width: 10rem;
      display: flex;
    }
    img {
      width: 0.2rem;
      height: 0.2rem;
      vertical-align: middle;
    }
    .active {
      //   margin-left: 1.8625rem;
      font-size: 0.2rem;
      font-weight: bold;
      color: #fe6869;
    }
    .bold {
      display: block;
      width: 100%;
      height: 0.0125rem;
      background: #fe6869;
    }
    ul {
      // width: 8.75rem;
      display: flex;
      li {
        margin-left: 0.5rem;
        font-size: 0.2rem;
        color: #000000;
        list-style: none;
        position: relative;
        .li-item {
          width: 2.2rem;
          // height: 1.875rem;
          background: #ffffff;
          box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.1);
          border-radius: 0.2rem;
          position: absolute;
          left: -0.6rem;
          top: 0.96rem;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding-top: 0.15rem;
          //   margin-left: 30px;
          z-index: 99999999;
          li {
            width: 100%;
            height: 0.5rem;
            background: #ffffff;
            border-radius: 0.1rem;
            color: #000000;
            font-size: 0.2rem;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0.125rem;
          }
          li:hover {
            width: 100%;
            height: 0.5rem;
            background: #fe6869;
            border-radius: 0.1rem;
          }
        }
      }
    }
  }
  .right {
    position: relative;
    img {
      width: 0.2rem;
      height: 0.2rem;
      vertical-align: middle;
    }
    span {
      margin-left: 0.5rem;
      font-size: 0.25rem;
      color: #4c4c4c;
    }
    .btn {
      width: 1.2rem;
      height: 0.45rem;
      display: inline-block;
      line-height: 0.45rem;
      border-radius: 0.225rem;
      border: 0.0125rem solid #4c4c4c;
      font-size: 0.2rem;
      color: #000000;
      cursor: pointer;
    }
    .btn_app {
      background: #000000;
      color: #fff !important;
    }
    .app {
      width: 3.75rem;
      height: 2.25rem;
      background: #ffffff;
      box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.1);
      border-radius: 0.2rem;
      z-index: 99999999;
      display: flex;
      // align-items: center;
      // justify-content: center;
      position: absolute;
      right: 0rem;
      top: 1rem;
      .divs {
        width: 1.875rem;
        height: 2.25rem;

        img {
          width: 1.5rem;
          height: 1.5rem;
          // margin: 0rem;
          margin-top: 0.25rem;
        }
        div {
          display: block;
          // width: 56px;
          height: 0.175rem;
          font-size: 0.175rem;
          color: #4c4c4c;
          margin-top: -0.3rem;
        }
      }
    }
  }
  // .mark-boxs {
  //   width: 100%;
  //   height: 13.5rem;
  //   background: rgba(0, 0, 0, 1) !important;
  //   opacity: 1;
  //   z-index: 9999999 !important;
  //   position: fixed;
  //   left: 0;
  //   top: 0;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   z-index: 99999999999;
  //   // position: relative;
  //   .content {
  //     width: 10rem;
  //     height: 6.375rem;
  //     background: #ffffff;
  //     border-radius: 0.2rem;
  //     display: flex;
  //     position: absolute;
  //     left: calc(50% - 5rem);
  //     top: calc(50% - 3.8875rem);
  //     opacity: 1;
  //     .left {
  //       width: 3.0625rem;
  //       height: 6.375rem;
  //       img {
  //         width: 100%;
  //         height: 100%;
  //       }
  //     }
  //     .right {
  //       width: 6.9375rem;
  //       position: relative;

  //       .cha {
  //         font-size: 0.375rem;
  //         // display: block;
  //         color: #000000;
  //         position: absolute;
  //         top: 0.1rem;
  //         right: 0.375rem;
  //       }
  //       .title {
  //         width: 4.875rem;
  //         height: 0.375rem;
  //         font-size: 0.375rem;
  //         font-weight: bold;
  //         color: #000000;
  //         line-height: 0.375rem;
  //         margin: 0.75rem 0rem 0rem 1.025rem;
  //       }
  //       el-form {
  //         z-index: 99999999999;
  //       }
  //       ::v-deep {
  //         .el-form {
  //           margin-top: 0.6875rem;
  //           margin-left: 0.75rem;
  //         }
  //         .el-form-item {
  //           width: 5.4375rem;
  //           margin-bottom: 0.4375rem;
  //         }
  //         .el-form-item__label {
  //           margin-top: 0.1rem;
  //         }
  //         .el-form-item__content ::after {
  //           background-color: red;
  //         }
  //         .el-input__inner {
  //           width: 4.1875rem;
  //           height: 0.625rem;
  //           background: #ffffff;
  //           border-radius: 0.3125rem;
  //           border: 0.0125rem solid #e2e2e1;
  //         }
  //       }
  //       .div {
  //         width: 5.4375rem;
  //         margin-left: 0.75rem;
  //         display: flex;
  //         position: relative;
  //         .imgs {
  //           width: 0.075rem;
  //           height: 0.075rem;
  //           position: absolute;
  //           top: 0.28rem;
  //           left: 0.1rem;
  //         }
  //         .span {
  //           width: 1.2rem;
  //           // margin-right: 0.3rem;
  //           display: block;
  //           margin-top: -0.15rem;
  //         }
  //         .fruits {
  //           width: 4.1875rem;
  //           height: 0.625rem;
  //           background: #ffffff;
  //           border-radius: 0.3125rem;
  //           border: 0.0125rem solid #e2e2e1;
  //           margin-left: 0.05rem;
  //           outline: none;
  //           text-indent: 1em;
  //           position: relative;

  //           ::placeholder {
  //             color: #c6cad1;
  //           }
  //         }
  //         .img {
  //           width: 0.2rem;
  //           height: 0.2rem;
  //           position: absolute;
  //           top: -0.15rem;
  //           right: 0.375rem;
  //           img {
  //             width: 100%;
  //             height: 100%;
  //           }
  //         }
  //         .Required {
  //           color: #f56c6c;
  //           font-size: 0.15rem;
  //           position: absolute;
  //           left: 1.25rem;
  //           top: 0.3rem;
  //         }
  //         .juese {
  //           width: 4.1875rem;
  //           height: 1.5rem;
  //           background: #ffffff;
  //           box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.1);
  //           border-radius: 0.2rem;
  //           position: absolute;
  //           right: 0rem;
  //           top: 0.725rem;
  //           div {
  //             width: 4.1875rem;
  //             height: 0.5rem;
  //             background: #ffffff;
  //             border-radius: 0.1rem;
  //             height: 0.375rem;
  //             display: flex;
  //             align-items: center;
  //             text-align: left;
  //             line-height: 0.5rem;
  //             // padding-left: 0.5rem;
  //             margin-top: 0.1rem;
  //             span {
  //               margin-left: 0.5rem;
  //             }
  //           }
  //           div:hover {
  //             width: 4.1875rem;
  //             height: 0.5rem;
  //             background: #f5f5f5;
  //             border-radius: 0.1rem;
  //             display: flex;
  //             align-items: center;
  //             text-align: left;
  //             line-height: 0.5rem;
  //             // padding-left: 0.5rem;
  //             margin-top: 0.1rem;
  //             span {
  //               margin-left: 0.5rem;
  //             }
  //           }
  //         }
  //       }
  //       .apply {
  //         width: 4.1875rem;
  //         height: 0.625rem;
  //         background: #000000;
  //         border-radius: 0.3125rem;
  //         color: #ffffff;
  //         font-size: 0.2rem;
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //         margin: 0.6875rem 0rem 0rem 2rem;
  //         z-index: -99999;
  //       }
  //     }
  //   }
  // }
}
.mark-boxs {
  width: 100%;
  height: 13.5rem;
  background: rgba(0, 0, 0, 0.3) !important;
  opacity: 1;
  z-index: 9999999999999 !important;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  // z-index: 99999999999;
  // position: relative;
  .content {
    width: 10rem;
    height: 6.375rem;
    background: #ffffff;
    border-radius: 0.2rem;
    display: flex;
    position: absolute;
    left: calc(50% - 5rem);
    top: calc(50% - 4.1875rem);
    opacity: 1;
    .left {
      width: 3.0625rem;
      height: 6.375rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      width: 6.9375rem;
      position: relative;

      .cha {
        font-size: 0.375rem;
        // display: block;
        color: #000000;
        position: absolute;
        top: 0.1rem;
        right: 0.375rem;
      }
      .title {
        width: 4.875rem;
        height: 0.375rem;
        font-size: 0.375rem;
        font-weight: bold;
        color: #000000;
        line-height: 0.375rem;
        margin: 0.75rem 0rem 0rem 1.025rem;
      }
      el-form {
        z-index: 99999999999;
      }
      ::v-deep {
        .el-form {
          margin-top: 0.6875rem;
          margin-left: 0.75rem;
        }
        .el-form-item {
          width: 5.4375rem;
          margin-bottom: 0.4375rem;
        }
        .el-form-item__label {
          margin-top: 0.1rem;
        }
        .el-form-item__content ::after {
          background-color: red;
        }
        .el-input__inner {
          width: 4.1875rem;
          height: 0.625rem;
          background: #ffffff;
          border-radius: 0.3125rem;
          border: 0.0125rem solid #e2e2e1;
        }
      }
      .div {
        width: 5.4375rem;
        margin-left: 0.75rem;
        display: flex;
        position: relative;
        .imgs {
          width: 0.075rem;
          height: 0.075rem;
          position: absolute;
          top: 0.28rem;
          left: 0.1rem;
        }
        .span {
          width: 1.2rem;
          // margin-right: 0.3rem;
          display: block;
          margin-top: -0.15rem;
        }
        .fruits {
          width: 4.1875rem;
          height: 0.625rem;
          background: #ffffff;
          border-radius: 0.3125rem;
          border: 0.0125rem solid #e2e2e1;
          margin-left: 0.05rem;
          outline: none;
          text-indent: 1em;
          position: relative;

          ::placeholder {
            color: #c6cad1;
          }
        }
        .img {
          width: 0.2rem;
          height: 0.2rem;
          position: absolute;
          top: -0.15rem;
          right: 0.375rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .Required {
          color: #f56c6c;
          font-size: 0.15rem;
          position: absolute;
          left: 1.25rem;
          top: 0.3rem;
        }
        .juese {
          width: 4.1875rem;
          height: 1.5rem;
          background: #ffffff;
          box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.1);
          border-radius: 0.2rem;
          position: absolute;
          right: 0rem;
          top: 0.725rem;
          div {
            width: 4.1875rem;
            height: 0.5rem;
            background: #ffffff;
            border-radius: 0.1rem;
            height: 0.375rem;
            display: flex;
            align-items: center;
            text-align: left;
            line-height: 0.5rem;
            // padding-left: 0.5rem;
            margin-top: 0.1rem;
            span {
              margin-left: 0.5rem;
            }
          }
          div:hover {
            width: 4.1875rem;
            height: 0.5rem;
            background: #f5f5f5;
            border-radius: 0.1rem;
            display: flex;
            align-items: center;
            text-align: left;
            line-height: 0.5rem;
            // padding-left: 0.5rem;
            margin-top: 0.1rem;
            span {
              margin-left: 0.5rem;
            }
          }
        }
      }
      .apply {
        width: 4.1875rem;
        height: 0.625rem;
        background: #000000;
        border-radius: 0.3125rem;
        color: #ffffff;
        font-size: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.6875rem 0rem 0rem 2rem;
        z-index: -99999;
      }
    }
  }
}
.headers {
  width: 24rem;
  height: 0.9rem;
  line-height: 0.9rem;
  position: fixed;
  top: 0rem;
  left: 0rem;
  display: flex;
  justify-content: center;
  z-index: 100;
  // background: url('@/assets/header-img.png') no-repeat;
  background: #ffffff;
  // opacity: 0.6;
  // backdrop-filter: blur(0.125rem);
  // background-size: 100% 100%;
  z-index: 99999999;
  // border-bottom: 0.0125rem solid rgba(255, 255, 255, 0.6);
  .header_logo {
    img {
      width: 2.15rem;
      height: 0.625rem;
      vertical-align: middle;
    }
  }
  .header_nav {
    height: 0.9rem;
    line-height: 0。9rem;
    cursor: pointer;
    margin-left: 1.8625rem;
    box-sizing: border-box;
    .tab {
      //   width: 10rem;
      display: flex;
    }
    img {
      width: 0.2rem;
      height: 0.2rem;
      vertical-align: middle;
    }
    .active {
      //   margin-left: 1.8625rem;
      font-size: 0.2rem;
      font-weight: bold;
      color: #fe6869;
    }
    .bold {
      display: block;
      width: 100%;
      height: 0.0125rem;
      background: #fe6869;
    }
    ul {
      // width: 8.75rem;
      display: flex;
      li {
        margin-left: 0.5rem;
        font-size: 0.2rem;
        color: #000000;
        list-style: none;
        position: relative;
        .li-item {
          width: 2.2rem;
          // height: 1.875rem;
          background: #ffffff;
          box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.1);
          border-radius: 0.2rem;
          position: absolute;
          left: -0.6rem;
          top: 0.96rem;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding-top: 0.15rem;
          //   margin-left: 30px;
          z-index: 99999999;
          li {
            width: 100%;
            height: 0.5rem;
            background: #ffffff;
            border-radius: 0.1rem;
            color: #000000;
            font-size: 0.2rem;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0.125rem;
          }
          li:hover {
            width: 100%;
            height: 0.5rem;
            background: #fe6869;
            border-radius: 0.1rem;
          }
        }
      }
    }
  }
  .right {
    position: relative;
    img {
      width: 0.2rem;
      height: 0.2rem;
      vertical-align: middle;
    }
    span {
      margin-left: 0.5rem;
      font-size: 0.25rem;
      color: #4c4c4c;
    }
    .btn {
      width: 1.2rem;
      height: 0.45rem;
      display: inline-block;
      line-height: 0.45rem;
      border-radius: 0.225rem;
      border: 0.0125rem solid #4c4c4c;
      font-size: 0.2rem;
      color: #000000;
    }
    .btn_app {
      background: #000000;
      color: #fff !important;
    }
    .app {
      width: 3.75rem;
      height: 2.25rem;
      background: #ffffff;
      box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.1);
      border-radius: 0.2rem;
      z-index: 99999999;
      display: flex;
      // align-items: center;
      // justify-content: center;
      position: absolute;
      right: 0rem;
      top: 1rem;
      .divs {
        width: 1.875rem;
        height: 2.25rem;

        img {
          width: 1.5rem;
          height: 1.5rem;
          // margin: 0rem;
          margin-top: 0.25rem;
        }
        div {
          display: block;
          // width: 56px;
          height: 0.175rem;
          font-size: 0.175rem;
          color: #4c4c4c;
          margin-top: -0.3rem;
        }
      }
    }
  }
  // .mark {
  //   width: 100%;
  //   height: 100%;
  //   background: rgba(0, 0, 0, 0.3);
  //   opacity: 1;
  //   z-index: 9999999;
  //   position: fixed;
  //   left: 0;
  //   top: 0;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   z-index: 99999999999;
  //   .content {
  //     width: 10rem;
  //     height: 6.375rem;
  //     background: #ffffff;
  //     border-radius: 0.2rem;
  //     display: flex;
  //     position: fixed;
  //     left: calc(50% - 5rem);
  //     top: calc(50% - 3.1875rem);
  //     .left {
  //       width: 3.0625rem;
  //       height: 6.375rem;
  //       img {
  //         width: 100%;
  //         height: 100%;
  //       }
  //     }
  //     .right {
  //       width: 6.9375rem;
  //       position: relative;

  //       .cha {
  //         font-size: 0.375rem;
  //         // display: block;
  //         color: #000000;
  //         position: absolute;
  //         top: 0.1rem;
  //         right: 0.375rem;
  //       }
  //       .title {
  //         width: 4.875rem;
  //         height: 0.375rem;
  //         font-size: 0.375rem;
  //         font-weight: bold;
  //         color: #000000;
  //         line-height: 0.375rem;
  //         margin: 0.75rem 0rem 0rem 1.025rem;
  //       }
  //       el-form {
  //         z-index: 99999999999;
  //       }
  //       ::v-deep {
  //         .el-form {
  //           margin-top: 0.6875rem;
  //           margin-left: 0.75rem;
  //         }
  //         .el-form-item {
  //           width: 5.4375rem;
  //           margin-bottom: 0.4375rem;
  //         }
  //         .el-form-item__label {
  //           margin-top: 0.1rem;
  //         }
  //         .el-form-item__content ::after {
  //           background-color: red;
  //         }
  //         .el-input__inner {
  //           width: 4.1875rem;
  //           height: 0.625rem;
  //           background: #ffffff;
  //           border-radius: 0.3125rem;
  //           border: 0.0125rem solid #e2e2e1;
  //         }
  //       }
  //       .div {
  //         width: 5.4375rem;
  //         margin-left: 0.75rem;
  //         display: flex;
  //         position: relative;
  //         .imgs {
  //           width: 0.075rem;
  //           height: 0.075rem;
  //           position: absolute;
  //           top: 0.28rem;
  //           left: 0.1rem;
  //         }
  //         .span {
  //           width: 1.2rem;
  //           // margin-right: 0.3rem;
  //           display: block;
  //           margin-top: -0.15rem;
  //         }
  //         .fruits {
  //           width: 4.1875rem;
  //           height: 0.625rem;
  //           background: #ffffff;
  //           border-radius: 0.3125rem;
  //           border: 0.0125rem solid #e2e2e1;
  //           margin-left: 0.05rem;
  //           outline: none;
  //           text-indent: 1em;
  //           position: relative;

  //           ::placeholder {
  //             color: #c6cad1;
  //           }
  //         }
  //         .img {
  //           width: 0.2rem;
  //           height: 0.2rem;
  //           position: absolute;
  //           top: -0.15rem;
  //           right: 0.375rem;
  //           img {
  //             width: 100%;
  //             height: 100%;
  //           }
  //         }
  //         .Required {
  //           color: #f56c6c;
  //           font-size: 0.15rem;
  //           position: absolute;
  //           left: 1.25rem;
  //           top: 0.3rem;
  //         }
  //         .juese {
  //           width: 4.1875rem;
  //           height: 1.5rem;
  //           background: #ffffff;
  //           box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.1);
  //           border-radius: 0.2rem;
  //           position: absolute;
  //           right: 0rem;
  //           top: 0.725rem;
  //           div {
  //             width: 4.1875rem;
  //             height: 0.5rem;
  //             background: #ffffff;
  //             border-radius: 0.1rem;
  //             height: 0.375rem;
  //             display: flex;
  //             align-items: center;
  //             text-align: left;
  //             line-height: 0.5rem;
  //             // padding-left: 0.5rem;
  //             margin-top: 0.1rem;
  //             span {
  //               margin-left: 0.5rem;
  //             }
  //           }
  //           div:hover {
  //             width: 4.1875rem;
  //             height: 0.5rem;
  //             background: #f5f5f5;
  //             border-radius: 0.1rem;
  //             display: flex;
  //             align-items: center;
  //             text-align: left;
  //             line-height: 0.5rem;
  //             // padding-left: 0.5rem;
  //             margin-top: 0.1rem;
  //             span {
  //               margin-left: 0.5rem;
  //             }
  //           }
  //         }
  //       }
  //       .apply {
  //         width: 4.1875rem;
  //         height: 0.625rem;
  //         background: #000000;
  //         border-radius: 0.3125rem;
  //         color: #ffffff;
  //         font-size: 0.2rem;
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //         margin: 0.6875rem 0rem 0rem 2rem;
  //         z-index: -99999;
  //       }
  //     }
  //   }
  // }
}
.mark {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 1;
  z-index: 9999999;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999999;
  .content {
    width: 10rem;
    height: 6.375rem;
    background: #ffffff;
    border-radius: 0.2rem;
    display: flex;
    position: fixed;
    left: calc(50% - 5rem);
    top: calc(50% - 4.1875rem);
    .left {
      width: 3.0625rem;
      height: 6.375rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      width: 6.9375rem;
      position: relative;

      .cha {
        font-size: 0.375rem;
        // display: block;
        color: #000000;
        position: absolute;
        top: 0.1rem;
        right: 0.375rem;
      }
      .title {
        width: 4.875rem;
        height: 0.375rem;
        font-size: 0.375rem;
        font-weight: bold;
        color: #000000;
        line-height: 0.375rem;
        margin: 0.75rem 0rem 0rem 1.025rem;
      }
      el-form {
        z-index: 99999999999;
      }
      ::v-deep {
        .el-form {
          margin-top: 0.6875rem;
          margin-left: 0.75rem;
        }
        .el-form-item {
          width: 5.4375rem;
          margin-bottom: 0.4375rem;
        }
        .el-form-item__label {
          margin-top: 0.1rem;
        }
        .el-form-item__content ::after {
          background-color: red;
        }
        .el-input__inner {
          width: 4.1875rem;
          height: 0.625rem;
          background: #ffffff;
          border-radius: 0.3125rem;
          border: 0.0125rem solid #e2e2e1;
        }
      }
      .div {
        width: 5.4375rem;
        margin-left: 0.75rem;
        display: flex;
        position: relative;
        .imgs {
          width: 0.075rem;
          height: 0.075rem;
          position: absolute;
          top: 0.28rem;
          left: 0.1rem;
        }
        .span {
          width: 1.2rem;
          // margin-right: 0.3rem;
          display: block;
          margin-top: -0.15rem;
        }
        .fruits {
          width: 4.1875rem;
          height: 0.625rem;
          background: #ffffff;
          border-radius: 0.3125rem;
          border: 0.0125rem solid #e2e2e1;
          margin-left: 0.05rem;
          outline: none;
          text-indent: 1em;
          position: relative;

          ::placeholder {
            color: #c6cad1;
          }
        }
        .img {
          width: 0.2rem;
          height: 0.2rem;
          position: absolute;
          top: -0.15rem;
          right: 0.375rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .Required {
          color: #f56c6c;
          font-size: 0.15rem;
          position: absolute;
          left: 1.25rem;
          top: 0.3rem;
        }
        .juese {
          width: 4.1875rem;
          height: 1.5rem;
          background: #ffffff;
          box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.1);
          border-radius: 0.2rem;
          position: absolute;
          right: 0rem;
          top: 0.725rem;
          div {
            width: 4.1875rem;
            height: 0.5rem;
            background: #ffffff;
            border-radius: 0.1rem;
            height: 0.375rem;
            display: flex;
            align-items: center;
            text-align: left;
            line-height: 0.5rem;
            // padding-left: 0.5rem;
            margin-top: 0.1rem;
            span {
              margin-left: 0.5rem;
            }
          }
          div:hover {
            width: 4.1875rem;
            height: 0.5rem;
            background: #f5f5f5;
            border-radius: 0.1rem;
            display: flex;
            align-items: center;
            text-align: left;
            line-height: 0.5rem;
            // padding-left: 0.5rem;
            margin-top: 0.1rem;
            span {
              margin-left: 0.5rem;
            }
          }
        }
      }
      .apply {
        width: 4.1875rem;
        height: 0.625rem;
        background: #000000;
        border-radius: 0.3125rem;
        color: #ffffff;
        font-size: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.6875rem 0rem 0rem 2rem;
        z-index: -99999;
      }
    }
  }
}
</style>
