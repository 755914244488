import request from '@/utils/request';
class home {
  constructor() {}
  // 获取资讯列表
  information(ids, limits, pages) {
    return request({
      url: `/api/information/index?category_id=${ids}&limit=${limits}&page=${pages}`,
      method: 'get',
      data: {},
    });
  }
  // 获取资讯分类
  informationCategory(data) {
    return request({
      url: `/api/information.category/index`,
      method: 'get',
      data,
    });
  }
  // 获取资讯详情
  informationFind(ids) {
    return request({
      url: `/api/information/find?id=${ids}`,
      method: 'get',
      data: {},
    });
  }
  // 获取短信验证码
  loginSend(phones) {
    return request({
      url: `/api/login/send?phone=${phones}`,
      method: 'get',
      data: {},
    });
  }
  // 申请试用
  applyTrail(data) {
    return request({
      url: `/api/apply.trail/add`,
      method: 'post',
      data,
    });
  }
  // 申请加盟
  applySettled(data) {
    return request({
      url: `/api/apply.settled/add`,
      method: 'post',
      data,
    });
  }
}

export default new home();
