<template>
  <div class="first">
    <div class="center-box">
      <p class="title">合作客户</p>
      <div class="customer">
        <img src="@/assets/customer.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.first {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .center-box {
    .title {
      height: 0.625rem;
      font-size: 0.55rem;
      line-height: 0.625rem;
      font-weight: bold;
      color: #000000;
    }
    .customer {
      width: 18rem;
      height: 6.3rem;
      margin: 0 auto;
      margin-top: 6%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
