<template>
  <div class="layout">
    <Header></Header>
    <AppMain class="layout_appmain"></AppMain>
    <Footer v-if="this.$route.path != '/home'"></Footer>
    <img
      class="kefu"
      @click="windowClick"
      src="../../src/assets/officialImg.png"
      alt=""
    />
  </div>
</template>

<script>
import Header from '@/layout/components/Header';
import Footer from '@/layout/components/Footer';
import { AppMain } from './components';
export default {
  name: 'Layout',
  components: {
    Header,
    Footer,
    AppMain,
  },
  methods: {
    windowClick() {
      window.open(
        'https://kf.ruanzhongzi.com/pcKefu/?propType=1&kefuId=xingmanyuan ',
        '_blank'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes bounce-up {
  25% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  50%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  75% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
}
.layout {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .layout_appmain {
    flex-grow: 1;
  }
  .kefu {
    width: 0.75rem;
    height: 0.75rem;
    position: fixed;
    right: 0.375rem;
    bottom: 0.375rem;
    z-index: 99999999;
    animation: bounce-up 1s linear infinite;
  }
}
</style>
